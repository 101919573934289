import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from './layout/layout';
import SEO from "./seo";
const { slugify } = require('../utils/slugify')

export default ({ pageContext }) => {
    const seo = {
          pageTitle: "Docs",
          pageDescription: "The CourseMaker Documentation Home"
    };

    // TODO: iterate over docs pages
    const allDocsPages = pageContext?.all_docs_pages;
    console.log(allDocsPages);

	return (
		<Layout footerHr="true">
			<SEO {...seo} />
        <main className="py-16 text-gray-900 lg:py-24">
        <div className="container">
        <h2 className="content-center p-4">Documentation Pages</h2>
          <div className="flex justify-center">
            <div className="w-full xl:w-9/12">
			      <div className="post-list space-y-4 md:space-y-6">
                {allDocsPages.map(docsPage => {
                    const slug = slugify(docsPage.slug) // Convert title to url-safe slug (RSS feed doesn't provide a slug)
                    return (
                        <Link to={`/docs/${slug}/`} className="flex flex-wrap md:flex-no-wrap px-10 py-8 bg-white rounded-lg shadow hover:shadow-lg transition transition-all duration-300">
                            <div className="block flex-auto">
                              <h2 className="text-xl lg:text-2xl">{docsPage.frontmatter.title}</h2>
                              </div>
                        </Link>
                        )
                })}
            </div>
          </div>
        </div>
        </div>
      </main>
    </Layout>
	);
};